<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="8">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="title">QUEM É DANIELA LAUBÉ?</v-card-title>
                <v-card-text
                  class="black--text"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div id="galeria" class="mt-5">
                <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topoautora" style="margin-bottom: 20vh;">
          <span class="mb-2"><a href="#galeria" class="linkGaleria">Galeria</a></span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">QUEM É DANIELA LAUBÉ?</v-card-title>
            <v-card-text
              class="black--text"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <div id="galeria" class="mt-5">
            <vue-picture-swipe :items="items"></vue-picture-swipe>
          </div>
          <span><a href="#topoautora" class="linkGaleria">Topo</a></span>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'Aautora',
  components: {
    AppBar
  },
  data () {
    return {
      DadosTexto: {
        Titulo: "QUEM É DANIELA LAUBÉ?",
        Texto: `Versátil, ousada, poeta, mulher: indeterminável.<br /><br />
                Da advocacia, partiu para a mediação de conflitos. 
                Mergulhada nas relações e mistérios dos indivíduos, passou aos estudos de psicanálise. 
                Ela é das artes desde criança: em tudo dança e faz poesia. 
                Paulistana da terra da garoa, residente em terras mineiras, é mãe de dois filhos e escreveu dois livros. 
                Ainda resta plantar uma árvore!<br /><br />
                Daniela Laubé começou a escrever poemas quando tinha por volta de 13 anos e alcançou primeiro
                lugar em um concurso de poesia no Colégio Santo Agostinho de São Paulo, onde estudava. 
                A possiblidade descoberta, de transpor emoções para versos, a acompanhou daí por diante. 
                Já na qualidade de ex-aluna, escreveu a peça “A Via Sacra em versos” para o grupo teatral da escola (2009). 
                Artes sempre lhe foram combustível. 
                Começou a estudar ballet clássico aos 8 anos de idade e, aos 18, deu aulas para crianças durante cinco anos. 
                Quando se tornou mãe pela primeira vez, surgiu o desejo, que começou a gestar, 
                de eternizar sua escrita em um futuro livro. Advogada, formada pela Faculdade de Direito de São Bernardo do 
                Campo (SP), escolheu a carreira almejando trabalhar com escrita e oralidade associadas às causas 
                que buscam justiça. Cursou LLM em Direito Empresarial na FGV-Rio, especializou-se em Direito Público 
                na Fundação Escola do Ministério Público de Minas Gerais, qualificou-se mediadora de conflitos pelo 
                CNJ e especializou-se em Mediação, Conciliação e Arbitragem pelo IDDE. 
                Trabalha com a mediação e Comunicação Não-Violenta, em contato próximo com a escuta empática dos indivíduos.
                Na cena literária, seus poemas ganharam o mundo com a criação do perfil no Instagram,
                durante a pandemia de Covid-19. Antes disso, estavam escondidos no fundo de antigas gavetas... 
                Selecionada e publicada por diversas obras coletivas, passou a ser reconhecida no termo “mulheriência”, 
                neologismo que cunhou para determinar uma vivência feminina, uma experiência de ser mulher, 
                que está para além do que o dicionário atual daria conta de dizer.  No ano de 2021, 
                lançou na Bienal Internacional do Livro do Rio de Janeiro seus livros 
                Preliminares – nudez no verso e Hoje não pode brincar lá fora.<br /><br />
                `
      },
      items: [
        {
          src: 'https://cdn.discordapp.com/attachments/999154086643511346/999154378046963762/autora_1.JPG?width=377&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154086643511346/999154486620737667/autora_1.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154086643511346/999154377308786798/autora_2.jpg?width=1005&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154086643511346/999154486838833162/autora_2.jpg',
          w: 1005,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154086643511346/999154377031958558/autora_1.jpeg?width=446&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154086643511346/999154487514112090/autora_1.jpeg',
          w: 446,
          h: 670
        }
      ]
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
